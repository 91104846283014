@import 'animation';
@import 'variable';
@import 'font';
@import 'reset';
@import 'mixins';
@import 'functions';
@import 'lang';

@import 'pages/page_template';
@import 'pages/home';
@import 'pages/register';
@import 'pages/faq';

@import 'components/navbar';
@import 'components/footer';

*:not(input) {
  -webkit-tap-highlight-color: transparent;
  // user-select: none;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

a {
  text-decoration: none;
}

.content-container {
  width: 100vw;
  height: 100vh;
  font-family: 'Gunar';
  @include px2res(font-size, 24);
  color: white;
  background: url($CDN + 'BG.jpg') 0% 0%/100% repeat-y;
  background-size: cover;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

button {
  @include px2res(font-size, 30);
  font-family: $base-font-family;
  color: white;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;

  &.disabled {
    cursor: default;
    pointer-events: none;

    &:active {
      filter: brightness(1);
    }
  }

  &:active {
    filter: brightness(1.1);
    transform: translate3d(0, 0.1vmax, 0);
  }
}
