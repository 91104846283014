.register {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url($CDN + 'BG.jpg') 0% 0%/100% repeat-y;
  color: white;
  @include px2res(padding-top, 75);
  @include px2res(padding-bottom, 150);

  @include portrait {
    min-height: 90vh;
    justify-content: flex-start;
    @include px2res(padding-top, 300);
  }

  .title {
    font-family: RefrigeratorDeluxeHeavy;
    @include px2res(font-size, 100);
    @include px2res(letter-spacing, 5);
    text-align: center;
    line-height: 130%;
    color: #fee500;
    @include px2res(margin-bottom, 10);
    animation: fadeIn 0.5s;

    @include portrait {
      line-height: 100%;
      @include px2res(font-size, 250);
      @include px2res(margin-bottom, 50);
    }
  }

  .desc {
    @include px2res(width, 1546);
    @include px2res(height, 81);
    @include px2res(font-size, 34);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url($CDN + 'Bar.png') center/100% 100% no-repeat;
    @include px2res(margin-bottom, -25);
    color: #e9e9e9;
    animation: fadeIn 0.5s;

    @include portrait {
      @include px2res(font-size, 42);
      transform: scale(1.25);
      transform-origin: bottom;
      @include px2res(margin-bottom, 125);
    }

    a {
      text-decoration: underline;
      text-decoration-color: #cecdcd;
      color: #e9e9e9;
      @include px2res(margin-left, 10);

      &:hover {
        color: #c5c5c5;
        text-decoration-color: #c5c5c5;
      }
    }
  }

  .tag {
    @include px2res(width, 1352);
    @include px2res(height, 374);
    @include px2res(margin-bottom, 25);
    animation: fadeIn 0.5s;

    @include portrait {
      transform: scale(1.25);
      transform-origin: bottom;
      @include px2res(margin-bottom, 75);
    }

    &.benefits {
      @include px2res(margin-top, 50);
      background: url($CDN + 'tag_Benefit.png') center/100% 100% no-repeat;
    }
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s;

    @include portrait {
      flex-wrap: wrap;
    }

    .ribbon {
      @include px2res(width, 335);
      @include px2res(height, 363);
      @include px2res(margin-left, 25);
      @include px2res(margin-right, 25);

      @include portrait {
        @include px2res(width, 586);
        @include px2res(height, 635);
        @include px2res(margin-bottom, 50);
        @include px2res(margin-left, 75);
        @include px2res(margin-right, 75);
      }

      &.ingame {
        background: url($CDN + 'Benefit_ingame.png') center/100% 100% no-repeat;
      }

      &.exposure {
        background: url($CDN + 'Benefit_Exposure.png') center/100% 100% no-repeat;
      }

      &.merc {
        background: url($CDN + 'Benefit_merchandise.png') center/100% 100% no-repeat;
      }

      &.network {
        background: url($CDN + 'Benefit_Network.png') center/100% 100% no-repeat;
      }
    }
  }
}
