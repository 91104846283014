footer {
  @include px2res(height, 190);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #1b1b1b;
  color: #fefefe;
  @include px2res(padding-right, 50);
  @include px2res(padding-left, 50);

  @include portrait {
    @include px2res(height, 500);
    align-items: center;
  }

  .logo {
    display: flex;
    align-items: center;
    @include px2res(margin-bottom, 20);

    @include portrait {
      width: 55%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include px2res(margin-bottom, 75);
    }

    .garena {
      @include px2res(width, 152);
      @include px2res(height, 46);
      background: url($CDN + 'Logo_Garena.png') center/100% 100% no-repeat;
      @include px2res(margin-right, 30);

      @include portrait {
        transform: scale(2);
        transform-origin: left;
        @include px2res(margin-right, 50);
      }
    }

    .activision {
      @include px2res(width, 136);
      @include px2res(height, 33);
      background: url($CDN + 'Logo_Activision.png') center/100% 100% no-repeat;
      @include px2res(margin-right, 30);

      @include portrait {
        transform: scale(2);
        transform-origin: center;
        @include px2res(margin-right, 0);
      }
    }

    .timi {
      @include px2res(width, 122);
      @include px2res(height, 50);
      background: url($CDN + 'logo_timi.png') center/100% 100% no-repeat;

      @include portrait {
        transform: scale(2);
        transform-origin: right;
      }
    }
  }

  .desc {
    width: 45%;
    font-family: Gunar;
    @include px2res(font-size, 18);
    opacity: 0.75;
    line-height: 125%;

    @include portrait {
      width: 75%;
      text-align: center;
      @include px2res(font-size, 32);
    }
  }
}
