nav {
  position: absolute;
  top: 0%;
  z-index: 1;
  width: 100%;
  @include px2res(height, 90);
  display: flex;
  align-items: center;
  background-color: black;
  border-bottom: 1px solid #ffa600;
  @include px2res(border-width, 5px);

  @include portrait {
    @include px2res(height, 250);
  }

  .logo {
    position: absolute;
    @include px2res(left, 100);
    @include px2res(bottom, -30);
    @include px2res(width, 110);
    @include px2res(height, 105);

    @include portrait {
      @include px2res(top, 65);
      transform: scale(2);
    }
  }

  .nav-list {
    position: absolute;
    @include px2res(right, 200);
    @include px2res(width, 600);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include portrait {
      @include px2res(right, 50);
      @include px2res(width, 1200);
    }

    a {
      position: relative;
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: RefrigeratorDeluxe;
      @include px2res(font-size, 34);
      text-transform: uppercase;
      color: #fefefe;

      @include portrait {
        @include px2res(font-size, 62);
      }

      &:hover {
        color: #fefefec4;
      }

      .marker {
        position: absolute;
        bottom: 0%;
        width: 100%;
        @include px2res(height, 15);
        background: url($CDN + 'Navigator.png') center/100% 100% no-repeat;
        animation: zoomIn 0.5s;
      }
    }
  }
}
