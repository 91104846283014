@import 'variable.scss';

@import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
//Chinese font
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC&display=swap');
// VN RU font
@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz&display=swap');
// Burmese (unicode) font
@import url('https://fonts.googleapis.com/css2?family=Padauk&display=swap');
// Number Font
@font-face {
  font-family: 'Bebas Neue Number';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bebas Neue Regular'), local('BebasNeue-Regular'),
    url(https://fonts.gstatic.com/l/font?kit=JTUSjIg69CK48gW7PXooxWtrzAbjydxFlRmQn1kIfkdt&skey=6bd981f07b300212&v=v1)
      format('woff2');
}
// Burmese (zawgyi)
@font-face {
  font-family: 'Zawgyi-One';
  src: url('https://dl.dir.freefiremobile.com/common/web_event/common/fonts/Zawgyi-One_V3.1.otf') format('opentype'),
    url('https://dl.dir.freefiremobile.com/common/web_event/common/fonts/Zawgyi-One_V3.1.woff') format('woff'),
    url('https://dl.dir.freefiremobile.com/common/web_event/common/fonts/Zawgyi-One_V3.1.ttf') format('truetype');
}

// BMG default font
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap');

@font-face {
  font-family: 'Gunar';
  src: url($CDN + 'fonts/Gunar.ttf') format('truetype');
}

@font-face {
  font-family: 'RefrigeratorDeluxe';
  src: url($CDN + 'fonts/RefrigeratorDeluxe.otf') format('opentype');
}

@font-face {
  font-family: 'RefrigeratorDeluxeBold';
  src: url($CDN + 'fonts/RefrigeratorDeluxeBold.otf') format('opentype');
}

@font-face {
  font-family: 'RefrigeratorDeluxeHeavy';
  src: url($CDN + 'fonts/RefrigeratorDeluxeHeavy.otf') format('opentype');
}
