.faq {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url($CDN + 'BG_3.jpg') 0% 0% / cover no-repeat;
  @include px2res(padding-top, 100);
  @include px2res(padding-bottom, 150);

  @include portrait {
    min-height: 95vh;
    background-position: center;
    align-items: flex-start;
    @include px2res(padding-top, 200);
  }

  .wrapper {
    width: 50%;
    @include px2res(width, 1560);
    @include px2res(height, 1536);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url($CDN + 'Placeholder_Faq.png') center/90% 100% no-repeat;
    @include px2res(padding-top, 185);
    @include px2res(padding-left, 160);
    @include px2res(padding-right, 150);
    animation: fadeIn 0.5s;

    @include portrait {
      width: 100%;
      @include px2res(height, 3000);
      background-size: 100% 100%;
      @include px2res(padding-top, 375);
      @include px2res(padding-right, 150);
      @include px2res(padding-left, 160);
    }

    .title {
      font-family: Gunar;
      font-weight: bold;
      @include px2res(font-size, 54);
      color: #ffe200;
      @include px2res(margin-bottom, 50);

      @include portrait {
        @include px2res(font-size, 100);
        @include px2res(margin-bottom, 75);
      }
    }

    .accordion {
      width: 100%;

      .accordion__button {
        position: relative;
        background-color: #fbde02;
        font-family: RefrigeratorDeluxe;
        @include px2res(font-size, 42);
        line-height: 125%;
        @include px2res(padding-top, 15);
        @include px2res(padding-bottom, 15);
        @include px2res(padding-left, 25);
        @include px2res(padding-right, 100);
        @include px2res(border-radius, 3);
        @include px2res(margin-bottom, 10);
        color: #000000;

        @include portrait {
          @include px2res(font-size, 70);
          @include px2res(padding-top, 30);
          @include px2res(padding-bottom, 30);
          @include px2res(padding-right, 150);
        }
      }

      .accordion__button:before {
        position: absolute;
        @include px2res(right, 50);
        @include px2res(width, 20);
        @include px2res(height, 20);
        @include px2res(margin-top, 7.5);
        transform: rotate(45deg);
      }

      .accordion__button[aria-expanded='true']::before,
      .accordion__button[aria-selected='true']::before {
        @include px2res(margin-top, 17.5);
        transform: rotate(-135deg);
      }

      .accordion__panel {
        background-color: #574b39;
        @include px2res(padding-top, 30);
        @include px2res(padding-bottom, 30);
        @include px2res(padding-left, 25);
        @include px2res(border-radius, 3);
        @include px2res(margin-bottom, 10);
        animation: none;
        transition: height 1s;

        p {
          font-family: RefrigeratorDeluxe;
          @include px2res(font-size, 28);
          line-height: 125%;
          color: #fbde02;
          animation: fadeIn 0.5s;

          @include portrait {
            @include px2res(font-size, 56);
          }
        }
      }
    }
  }
}
