body[data-lang=vi],
body[data-lang=vn],
body[data-lang=ru] {
  *,
  *::before,
  *::after {
    font-family: $vnru-font-family;

  }
}

body[data-lang=zh],
body[data-lang=zh-tw] {
  *,
  *::before,
  *::after {
    font-family: $zh-font-family;
  }
}

body[data-lang=ar],
body[data-lang=ur]{
    direction: rtl;    
}

body[data-lang=my] {
  *,
  *::before,
  *::after {
    font-family: $my-font-family;
  }
}
