.home {
  .first {
    position: relative;
    width: 100%;
    height: 95vh;
    background: url($CDN + 'header_image.jpg') 0% 20% / cover no-repeat;
    animation: fadeIn 0.5s;

    @include portrait {
      background-position: 25% 0%;
      height: 100vh;
    }

    .wrapper {
      position: absolute;
      @include px2res(right, 175);
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      animation: slideInLeft 1s;

      @include portrait {
        @include px2res(right, 0);
        width: 100%;
        background-color: #00000046;
        animation: none;
      }

      .title {
        @include px2res(width, 700);
        @include px2res(height, 357);

        @include portrait {
          transform: scale(2.5);
          transform-origin: bottom;
          @include px2res(margin-bottom, 50);
        }
      }

      .text {
        width: 90%;
        @include px2res(margin-top, 10);
        @include px2res(margin-bottom, 30);

        @include portrait {
          @include px2res(margin-bottom, 100);
        }

        p {
          font-family: RefrigeratorDeluxeHeavy;
          @include px2res(font-size, 38);
          @include px2res(letter-spacing, 2.5);
          text-align: center;
          color: #ffff00;
          line-height: 130%;

          @include portrait {
            @include px2res(font-size, 100);
          }
        }

        span {
          background-color: #ffff00;
          color: #000000;
          @include px2res(padding-left, 10);
          @include px2res(padding-right, 7.5);
        }
      }

      .placeholder {
        @include px2res(width, 561);
        @include px2res(height, 205);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url($CDN + 'placeholdersocmed.png') center/90% 90% no-repeat;

        @include portrait {
          transform: scale(2);
          transform-origin: top;
        }

        .dc,
        .ig,
        .wa {
          @include px2res(width, 108);
          @include px2res(height, 108);
          @include px2res(margin-left, 15);
          @include px2res(margin-right, 15);
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
          }
        }

        .dc {
          background: url($CDN + 'logo_discord.png') center/80% 80% no-repeat;
        }

        .ig {
          background: url($CDN + 'Logo_IG.png') center/80% 80% no-repeat;
        }

        .wa {
          background: url($CDN + 'logo_WA.png') center/80% 80% no-repeat;
        }
      }
    }
  }

  .second {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url($CDN + 'BG.jpg') 0% 0%/100% repeat-y;
    @include px2res(padding-top, 75);
    @include px2res(padding-bottom, 150);
    animation: fadeIn 0.5s;

    @include portrait {
      @include px2res(padding-top, 250);
      @include px2res(padding-bottom, 300);
    }

    .tag {
      @include px2res(width, 1352);
      @include px2res(height, 374);
      @include px2res(margin-bottom, 25);

      @include portrait {
        transform: scale(1.25);
        transform-origin: bottom;
        @include px2res(margin-bottom, 75);
      }

      &.events {
        background: url($CDN + 'tag_thingstodohere.png') center/100% 100% no-repeat;
      }

      &.benefits {
        @include px2res(margin-top, 50);
        background: url($CDN + 'tag_Benefit.png') center/100% 100% no-repeat;

        @include portrait {
          @include px2res(margin-top, 150);
        }
      }
    }

    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      @include portrait {
        flex-wrap: wrap;
      }

      .ribbon {
        @include px2res(width, 335);
        @include px2res(height, 363);
        @include px2res(margin-left, 25);
        @include px2res(margin-right, 25);

        @include portrait {
          @include px2res(width, 586);
          @include px2res(height, 635);
          @include px2res(margin-bottom, 50);
          @include px2res(margin-left, 75);
          @include px2res(margin-right, 75);
        }

        &.mabar {
          background: url($CDN + 'Things_Mabar.png') center/100% 100% no-repeat;
        }

        &.tourney {
          background: url($CDN + 'Things_Tournament.png') center/100% 100% no-repeat;
        }

        &.nobar {
          background: url($CDN + 'Things_Gathering.png') center/100% 100% no-repeat;
        }

        &.ingame {
          background: url($CDN + 'Benefit_ingame.png') center/100% 100% no-repeat;
        }

        &.exposure {
          background: url($CDN + 'Benefit_Exposure.png') center/100% 100% no-repeat;
        }

        &.merc {
          background: url($CDN + 'Benefit_merchandise.png') center/100% 100% no-repeat;
        }

        &.network {
          background: url($CDN + 'Benefit_Network.png') center/100% 100% no-repeat;
        }
      }
    }
  }
}
